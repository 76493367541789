<template>
  <div id="app" v-if="imageLoad">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageLoad: false,
      allImages: [
        "quiz/1.png",
        "quiz/2.png",
        "quiz/3.png",
        "quiz/4.png",
        "quiz/5.png",
        "quiz/6.png",
        "quiz/7.png",
        "quiz/8.png",
        "quiz/9.png",
        "quiz/10.png",
        "quiz/11.png",
        "quiz/12.png",
        "quiz/13.png",
        "quiz/14.png",
        "quiz/15.png",
        "card/intro.png",
        "card/loading.png",
        "card/loading.png",
        "character/감자깡.png",
        "character/고구마깡.png",
        "character/매운새우깡.png",
        "character/매운쌀새우깡.png",
        "character/새우깡.png",
        "character/쌀새우깡.png",
        "character/양파깡.png",
        "character/옥수수깡.png",
        "teamwork/감자깡.png",
        "teamwork/고구마깡.png",
        "teamwork/매운새우깡.png",
        "teamwork/매운쌀새우깡.png",
        "teamwork/새우깡.png",
        "teamwork/쌀새우깡.png",
        "teamwork/양파깡.png",
        "teamwork/옥수수깡.png"
      ]
    };
  },
  created() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      try {
        Promise.all(
          this.allImages.map(async src => {
            return await this.preLoadImage(src);
          })
        )
          .then(() => {
            setTimeout(() => {
              this.imageLoad = true;
            }, 100);
          })
          .catch(e => {
            console.error(e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    preLoadImage(src) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = `https://kkangbti-ns.com/${src}`;
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(true);
        };
      });
    }
  }
};
</script>